<template>

	
	<div class="cont" id="p10">
		<div class="page-title">
			{{ program.name }} 
			<v-btn text class="">대량 결제</v-btn>
			<v-btn small class="success2" style="margin-left: 180px;"  :to="{ name: 'PaymentsSms'}">건별 결제<v-icon small>mdi-chevron-right</v-icon></v-btn>
		</div>
		<div class="c-list">
			<div class="c01" style="width: 450px !important;">
				<div class="s01">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">결제정보</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">샘플 양식</div>
								<div class="si-desc setting" style="display: inline-block; text-align: right !important;">
									<v-btn
										small
										class="primary"
										href="/files/sms.sample.xlsx"
										target="_blank"
									>
										다운로드
									</v-btn>
								</div>
							</div>
							<div class="s-content w100" v-if="false">
								<div class="si-title">요청일시</div>
								<div class="si-desc setting">
									<input type="text" class="datepicker mr-10"
										placeholder="날짜"
										v-model="item.inquiryDate"
										@click="setSearchDate(); item.inquiryDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="item.inquiryDate"
										no-title
										scrollable
										style="position: absolute; top: 40px; left: 10px; border: 1px solid #bbb; z-index: 99;"
										@change="datePicker.start.view = false"
									></v-date-picker>
									<select
										v-model="item.paymentRequestTime"
									>
										<option value="">시간</option>
										<option
											v-for="hour in 24"
											:key="hour"
											value="{hour}"
										>{{ hour }}시</option>
									</select>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">엑셀 파일</div>
								<div class="si-desc setting">
									<v-file-input
										v-model="file"
										accept="xlsx"
										prepend-icon="mdi-microsoft-excel"
									></v-file-input>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s04">
					<a @click="save" class="focus"><div class="btn-submit" :class="{active: isSave}">등록</div></a>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	
	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	import { date } from '@/resources/date/date'
	
	export default{
		name: "PaymentCard"
		,props: ['user', 'codes']
		,components: { }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[3].list[1].name
				}
				,item: {
					inquiryDate: ''
					,paymentRequestTime: ''
					,file: ''
				}
				,file: {}
				,cardNumber: ['','','','']
				,rules: rules
				,isSave: false
				,view: {
					item: false
				}
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,inquiryStartDate: ''
				,inquiryTime: ''
			}
		}
		,computed: {
		}
		,methods: {
			save: async function(){
				if(this.isSave){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/sms/approval/file'
							,data: this.item
							,authorize: true
							,multipart: true
						})

						if(result.success){
							this.$emit('setNotify', { type: 'success', message: '결제가 등록되었습니다'})
							this.$router.push({ name: 'PaymentsList' })
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message})
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E})
					}
				}
			}
			,setSearchDate: async function(){
				this.inquiryStartDate = date.getToday()
			}
		}
		,created: function(){
		}
		,watch: {
			item: {
				deep: true
				,handler: function(call){
					this.isSave = true
					for(const [key, value] of Object.entries(call)){
						console.log(key + ' : ' + value)
						if(!value && key != 'memo' && key != 'inquiryDate' && key != 'paymentRequestTime'){
							this.isSave = false
							return
						}
					}
				}
			}
			,file: {
				handler: function(call){
					this.$set(this.item, 'file', call)
				}
			}
		}
	}
</script>























